// import { action } from "typesafe-actions";
import { message } from 'antd';
import { take, call, put, fork } from 'redux-saga/effects';
import { go } from 'connected-react-router';
import { signin } from 'utils/apollo';
import * as qs from 'utils/queryString';

import { setPreviousLoginEmail, signin as signinActions } from './actions';
import ActionTypes from './constants';

function* signFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SIGNIN_SUBMIT);
    yield put(signinActions.request());
    const response = yield call(signin, payload);
    if (!response.errors) {
      localStorage.setItem('token', response.token);
      localStorage.setItem('rememberToken', response.rememberMeToken);
      const isUnder18FromSessionStorage = window.sessionStorage.getItem('isUnder18')
        ? String(window.sessionStorage.getItem('isUnder18') === 'true')
        : 'true';
      localStorage.setItem('isUnder18', isUnder18FromSessionStorage);
      localStorage.setItem('previousLoginEmail', payload.email);
      // @ts-ignore
      yield put(signinActions.success());
      yield put(go(qs.parse(location.search).redirect || '/'));
    } else {
      yield put(signinActions.failure(response.errors));
      response.errors.map((e: any) => message.error(e.message));
    }
  }
}

// Individual exports for testing
export default function* signinPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(signFlow);

  const previousLoginEmail = window.localStorage.getItem('previousLoginEmail');
  if (previousLoginEmail) {
    yield put(setPreviousLoginEmail(previousLoginEmail));
  }
}
