/*
 *
 * SigninPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/SigninPage/DEFAULT_ACTION',
  PREVIOUS_LOGIN_EMAIL = 'app/SigninPage/GET_PREVIOUS_LOGIN_EMAIL',
  SIGNIN_SUBMIT = 'app/SigninPage/SIGNIN_SUBMIT',
  SIGNIN_REQUEST = 'app/SigninPage/SIGNIN_REQUEST',
  SIGNIN_SUCCESS = 'app/SigninPage/SIGNIN_SUCCESS',
  SIGNIN_FAILURE = 'app/SigninPage/SIGNIN_FAILURE',
}

export default ActionTypes;
